.codeContainer {
  display: flex;
  width: 100%;
  gap: 0.5rem;
}

.codeInputElement:-webkit-autofill,
.codeInputElement:-webkit-autofill:hover,
.codeInputElement:-webkit-autofill:focus {
  opacity: 0 !important;
}

.codeChar {
  font-family: var(--fontFamilySans);
  font-size: 2rem;
  font-feature-settings: 'tnum' 1, 'ss01' 1;

  border-radius: 0.25rem;
  border: 1px solid var(--colorBorderDefault);
  background-color: white;
  outline: 0 none;

  transition-property: opacity, color, background-color, border-color, box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}

.codeCharSelected {
  border-color: var(--colorBlue);
  box-shadow: 0 0 0 4px var(--colorBlueFade);
}

.codeCharFilled {
  border-color: var(--colorBlue5);
}
