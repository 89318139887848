.tagPickerContainer {
  width: 100%;
  position: relative;
}

.react-tags {
  max-height: 32px;
  padding: 0 12px 0 12px !important;
  border: 1px solid var(--colorGray3);
  border-radius: 2px;
  background: var(--colorPageBackgroundDefault);
  box-sizing: border-box;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
  width: 100%;
}

.containerDisabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: auto;
}

.react-tags.error {
  border: 1px solid #e12923 !important;
}

.react-tags.is-active {
  border-color: var(--colorBlue);
  box-shadow: 0 0 0 4px var(--colorBlueFade);
  outline: 0 none;
}

.react-tags.is-disabled {
  opacity: 0.75;
  background-color: #eaeef2;
  /* Prevent any clicking on the component */
  pointer-events: none;
  cursor: not-allowed;
}

.react-tags.is-invalid {
  border-color: #e12923;
  box-shadow: 0 0 0 2px rgba(253, 86, 83, 0.25);
}

.react-tags__label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.react-tags__list {
  /* Do not use display: contents, it's too buggy */
  display: inline;
  padding: 0;
}

.react-tags__list-item {
  display: inline;
  list-style: none;
}

.react-tags__tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 0 0;
  padding: 3px 3px;
  border: 0px none var(--colorGray2);
  border-radius: 2px;
  background: var(--colorGray2);

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__tag::after {
  content: '×';
  color: var(--colorGray10);
  display: inline-block;
  margin-left: 6px;
  margin-right: 4px;
}

.react-tags__combobox {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 1px;

  /* prevents autoresize overflowing the container */
  max-width: 100%;
}

.react-tags__combobox-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__combobox-input::-ms-clear {
  display: none;
}

.react-tags__combobox-input::placeholder {
  color: #7c7d86;
  opacity: 1;
}

.react-tags__listbox {
  position: absolute;
  z-index: 2;
  top: 32px;
  left: 0;
  right: 0;
  margin-left: -1px;
  max-height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #aaa;
  border-top: none;
  background: #ffffff;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.react-tags__listbox::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.react-tags__listbox-option {
  padding: 0.375rem 0.5rem;
}

.react-tags__listbox-option:hover {
  cursor: pointer;
  background: var(--colorBlue3);
}

.react-tags__listbox-option:not([aria-disabled='true']).is-active {
  background: var(--colorPrimary);
  color: #ffffff;
}

.react-tags__listbox-option[aria-disabled='true'] {
  color: var(--colorFieldDisabled);
  cursor: not-allowed;
  pointer-events: none;
}

.react-tags__listbox-option[aria-selected='true']::after {
  content: '✓';
  margin-left: 0.5rem;
}

.react-tags__listbox-option[aria-selected='true']:not(.is-active)::after {
  color: var(--colorPrimary);
}

.react-tags__listbox-option-highlight {
  background-color: #ffdd00;
}

/* TAG STYLES */
/* Round badges */
.round.gray,
.round.gray:after {
  background-color: var(--colorGray9);
  color: white;
  border-radius: 10px;
  padding-left: 10px;
}

.round.green,
.round.green:after {
  background-color: var(--colorGreen8);
  color: white;
  border-radius: 10px;
  padding-left: 10px;
}

.round.blue,
.round.blue:after {
  background-color: var(--colorBlue8);
  color: white;
  border-radius: 10px;
  padding-left: 10px;
}

.round.red,
.round.red:after {
  background-color: var(--colorRed8);
  color: white;
  border-radius: 10px;
  padding-left: 10px;
}

.round.yellow,
.round.yellow:after {
  background-color: var(--colorYellow8);
  color: white;
  border-radius: 10px;
  padding-left: 10px;
}

.round.purple,
.round.purple:after {
  background-color: var(--colorPurple8);
  color: white;
  border-radius: 10px;
  padding-left: 10px;
}

/* Tinted badges */
.tinted.gray,
.tinted.gray:after {
  background-color: var(--colorGray2);
  color: var(--colorGray10);
}

.tinted.green,
.tinted.green:after {
  background-color: var(--colorGreen2);
  color: var(--colorGreen8);
  padding-left: 10px;
}

.tinted.blue,
.tinted.blue:after {
  background-color: var(--colorBlue2);
  color: var(--colorBlue8);
}

.tinted.red,
.tinted.red:after {
  background-color: var(--colorRed2);
  color: var(--colorRed8);
}

.tinted.yellow,
.tinted.yellow:after {
  background-color: var(--colorYellow2);
  color: var(--colorYellow8);
}

.tinted.purple,
.tinted.purple:after {
  background-color: var(--colorPurple2);
  color: var(--colorPurple8);
}

/* Solid badges */
.solid.gray,
.solid.gray:after {
  background-color: var(--colorGray8);
  color: white;
}

.solid.green,
.solid.green:after {
  background-color: var(--colorGreen7);
  color: white;
}

.solid.blue,
.solid.blue:after {
  background-color: var(--colorBlue7);
  color: white;
}

.solid.red,
.solid.red:after {
  background-color: var(--colorRed7);
  color: white;
}

.solid.yellow,
.solid.yellow:after {
  background-color: var(--colorYellow7);
  color: white;
}

.solid.purple,
.solid.purple:after {
  background-color: var(--colorPurple7);
  color: white;
}
