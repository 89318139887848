.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 368px;
}

.cardContainer {
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  border-radius: 2px;
  border: 1px solid #e2e2e2;
  cursor: pointer;
  background: transparent;
}

.selectedCardContainer {
  border: 1px solid var(--colorBlue8);
  box-shadow: 0px 0px 0px 4px rgba(34, 100, 209, 0.1);
}

.disabledCardContainer {
  pointer-events: none;
  background: var(--colorGray1);
}

.disabledCardContainer .boldText,
.disabledCardContainer .location {
  color: var(--colorGray8);
}

.businessIdSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}

.boldText {
  composes: ui14SemiBold from '../../../../styles/typeography.css';
  color: black;
  margin: 0;
  text-align: left;
}

.businessStatus {
  text-transform: capitalize;
}

.fieldTitle {
  composes: ui12Medium from '../../../../styles/typeography.css';
  color: var(--colorGray7);
  margin: 0;
  text-align: left;
}

.location {
  composes: ui14Regular from '../../../../styles/typeography.css';
  color: black;
  margin: 0;
  text-align: left;
}

.private {
  color: var(--colorRed8);
}
