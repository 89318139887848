.selectorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Cards Section */
.cardsContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

/* Card Section */
.cardContainer {
  all: unset;
  width: 198px;
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  position: relative;
}

.cardImageContainer {
  background-color: var(--colorGray2);
  height: 128px;
  width: 198px;
  border-radius: 2px;
  object-fit: contain;
  border: 1px solid white; /* Avoids background-border glitch for unselected cards and image-shifting when going from unselected to selected */
  background-size: cover; /* Allows using double-res images (384*256 pixels) */
}
.cardTitle {
  composes: ui14Medium from '../../styles/typeography.css';
  color: var(--colorGray11);
  margin: 0;
  margin-top: 0.75rem;
}
.cardSubtitle {
  composes: ui14Regular from '../../styles/typeography.css';
  color: var(--colorGray6);
  margin: 0;
}

/* Selected Card */
.selectedBadge {
  top: 6px;
  margin: 0;
  right: 6px;
  width: 59px;
  color: white;
  height: 1.5rem;
  font-weight: 500;
  position: absolute;
  text-align: center;
  border-radius: 2px;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-family: var(--fontFamilySans);
  background-color: var(--colorGreen7);
}
.selectedCardImageContainer {
  border-radius: 2px;
  border: 1px solid var(--colorGreen8);
  box-shadow: 0px 0px 0px 6px rgba(101, 177, 104, 0.45);
}

.disabledCard {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

@media (--viewportPhoneMin) {
  .cardsContainer {
    padding: 0;
  }
  .cardContainer {
    max-width: 167px;
  }
  .cardImageContainer {
    width: 167px;
    height: 128px;
  }
}

@media (--viewportTabletMin) {
  .cardsContainer {
    display: grid;
    width: fit-content;
    align-items: flex-start;
    justify-content: space-between;
    grid-template-columns: repeat(3, 203px);
  }
  .cardContainer {
    width: 203px;
  }
  .cardImageContainer {
    width: 203px;
    height: 128px;
  }
}

@media (--viewportTabletLandscapeMin) {
  .cardsContainer {
    grid-template-columns: repeat(4, 198px);
  }
  .cardContainer {
    width: 198px;
  }
  .cardImageContainer {
    width: 198px;
    height: 128px;
  }
}

@media (--viewportLargeDesktopMin) {
  .cardsContainer {
    grid-template-columns: repeat(5, 198px);
  }
}
