.modalOuterContainer {
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.18);
}

.modalContainer {
  background-color: white;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* min-width: 704px; */
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid var(--colorGray2);
}

.headerLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.modalTitle {
  composes: ui16Medium from '../../styles/typeography.css';
}

.modalDescriptor {
  composes: ui16Regular from '../../styles/typeography.css';
  color: var(--colorGray6);
}

.modalTitle + .modalDescriptor {
  margin-left: 16px;
}

.modalClose {
  color: var(--colorBlack2);
  cursor: pointer;
}

.modalBody {
  padding: 24px;
  composes: ui14Regular from '../../styles/typeography.css';
  width: 100%;
}

.modalFooter {
  border-top: 1px solid var(--colorGray2);
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footerLeft {
}

.footerRight > * + * {
  margin-left: 16px;
}
