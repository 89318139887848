.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.inputContainer {
  margin-bottom: 16px;
}

.manualBtn {
  align-self: flex-end;
  color: var(--colorGray6) !important;
}

.labelAddress {
  composes: ui12Medium from '../../styles/typeography.css';
  color: var(--colorGray8);
}

.hideSubmitButton {
  display: none;
}
