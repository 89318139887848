.checkboxListContainer {
  margin-top: 4px;
  display: grid;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.checkbox {
  width: fit-content;
}

.testSwitch {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: unset;
}
