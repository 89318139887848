.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.asyncSelect {
  width: inherit;
}

.optionItemContainer,
.optionItemLowerHalf {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  composes: ui14Regular from '../../styles/typeography.css';
}

.optionItemContainer p,
.optionItemLowerHalf p {
  margin-bottom: 0;
}

.optionItemUpperHalf {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}
.locTypeBadge {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
.nickname {
  composes: ui14SemiBold from '../../styles/typeography.css';
  margin-bottom: 0;
}

.addressMenuItemsContiner {
  border-bottom: 1px solid var(--colorGray2);
}

.addAddressBtnContainer {
  display: flex;
  flex-direction: column;
  margin: 16px;
}

.disabled {
  cursor: not-allowed;
}
