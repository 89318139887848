.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--colorGray3);
  border-radius: 2px;
  padding: 6px 12px;
  gap: 8px;
}

.container.default.isActive {
  border-color: var(--colorBlack2);
  box-shadow: 0 0 0 4px var(--colorBlackFade);
  outline: 0 none;
}
.container.primary.isActive {
  border-color: var(--colorBlue);
  box-shadow: 0 0 0 4px var(--colorBlueFade);
  outline: 0 none;
}

.container.isError {
  border-color: var(--colorRed);
}
.container.isError.isActive {
  box-shadow: 0 0 0 4px var(--colorRedFade);
}

.container.disabled {
  background-color: var(--colorGray1);
}

.left {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 8px;
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.cardNumber {
  composes: ui14Regular from '../../styles/typeography.css';
  border: none;
  width: 100%;
}
.expiry {
  composes: ui14Regular from '../../styles/typeography.css';
  border: none;
  max-width: 60px;
}
.cvc {
  composes: ui14Regular from '../../styles/typeography.css';
  border: none;
  max-width: 33px;
}

.cardNumber:focus,
.expiry:focus,
.cvc:focus {
  outline: 0 none;
}

.cardNumber::placeholder,
.expiry::placeholder,
.cvc::placeholder {
  color: var(--colorInputPlaceholder);
}

.cardNumber.disabled,
.expiry.disabled,
.cvc.disabled {
  background-color: var(--colorGray1) !important;
}
