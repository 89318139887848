.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.fieldWidth {
  width: 50%;
}
