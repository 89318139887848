.container {
  padding: 2rem;
  color: var(--colorTextMuted);
  text-align: center;
}

.spinner {
  width: 3rem;
  height: 3rem;
  color: var(--colorProgressSpinnerHighlight);
}

.container.inline {
  padding: 0;
  text-align: left;
  display: flex;
}

.container.inline .spinner {
  width: 60px;
  height: 30px;
}
