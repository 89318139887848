.dropdown {
  position: absolute;
  z-index: 10;
  top: 2.5rem;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 1rem 4.5rem rgba(0, 0, 0, 0.35);
  overflow: hidden;
  cursor: default;
  max-width: 260px;
}

.dropdown.right {
  right: auto;
  left: 0;
}

.dropdown.left {
  right: 0;
  left: auto;
}

@media (--viewportPhoneMax) {
  .dropdown {
    position: fixed;
    width: calc(100vw - 3rem);
    top: 14rem; /* magic number so dropdowns on search page work on mobile */
    right: 0 !important;
    left: 0 !important;
    margin: 0 auto;
  }
}

.dropdownInner {
  overflow-y: scroll;
  max-height: calc(100vh - 150px);
}

/* Header */

.header {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 1.25rem;
  background-color: var(--colorTan2);
  color: var(--colorBlack2);
}

.headerTitle {
  composes: ui14Medium from '../../styles/typeography.css';
  color: var(--colorTextDefault);
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.headerCaption {
  color: var(--colorTextMuted);
  overflow-x: hidden;
  text-overflow: ellipsis;
}

/* Dropdown Menu Item */

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  color: var(--colorTextDefault);
  background-color: white;
  cursor: pointer;
}

.dropdownItem.isSelected {
  background-color: var(--colorDropdownItemSelectedBackground);
}

.dropdownItem:hover {
  background-color: var(--colorDropdownItemHoverBackground);
}

.dropdownItemIcon {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.dropdownItemLabelWrapper {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.dropdownItemLabel {
  display: block;
  color: var(--colorTextDefault);
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.dropdownItemCaption {
  color: var(--colorTextMuted);
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.dropdownItemBadge {
  margin-right: 10px;
}

.dropdownItemCheckmark {
  margin-left: auto;
  color: var(--colorPrimary);
}

/* Dropdown Section */

.dropdownSection {
  padding: 0.5rem 0;
  list-style: none;
  white-space: nowrap;
  min-width: 16rem;
  max-width: calc(100vw - 32px);
}

.dropdownSection.hasTitle {
  padding: 0 0 0.5rem 0;
}

.dropdownSection:first-of-type {
  padding-top: 0.5rem;
}

.dropdownSection:last-of-type {
  padding-bottom: 0.5rem;
}

.dropdownSectionTitle {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  border-top: 1px solid var(--colorBorderMuted);
  color: var(--colorTextMuted);
}
