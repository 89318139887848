.container {
  width: 100%;
  max-height: 32px;
}

.leftIcon {
  margin-left: 14px;
  margin-right: 12px;
}

.clearIcon {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dismiss {
  padding: 0;
}

.customMenuList {
  font-size: 12px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  composes: ui14Regular from '../../styles/typeography.css';
}

.customMenuList::-webkit-scrollbar {
  width: 1px;
}

.customMenuList::-webkit-scrollbar-track {
  background: transparent;
}

.customMenuList::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.customMenuList::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.customOption {
  transition: background 60ms;
}
.customOption:hover {
  transition-delay: 60ms;
  background: var(--colorGray1);
}
.customOption.custom-select__option--is-focused {
  background: none;
}
.customOption.fuzzySelect__option--is-selected {
  background: var(--colorGray4);
}

.optionName {
  color: var(--colorBlack);
}

.disabled {
  color: var(--colorGray6);
}

.isError {
  border-color: var(--colorRed8) !important;
  box-shadow: 0 0 0 4px var(--colorRedFade);
}
