.container {
  width: 100%;
}

.customOption {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;

  transition: background 60ms;
}

.customGroup {
  border-bottom: 1px solid var(--colorGray2);
}

.isSubRegionIndent {
  padding-left: 32px !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
